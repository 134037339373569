<template>
  <page title="Livraisons">


    <h3 class="font-medium mb-2">Disponibilités: </h3>
    <div class="w-full flex flex-col items-start">
      <label class="text-gray-700 flex items-center py-2 cursor-pointer">
        <input type="radio" v-model="delivery.availability" name="availability" value="full_time" class="form-radio h-4 w-4">
        <span class="pl-2">À temps plein</span>
      </label>
      <label class="text-gray-700 flex items-center py-2 cursor-pointer">
        <input type="radio" v-model="delivery.availability" name="availability" value="partial_time" class="form-radio h-4 w-4">
        <span class="pl-2">À temps partiel</span>
      </label>
    </div>

    <h3 class="font-medium mb-2 mt-4" v-if="delivery.availability === 'partial_time'">Précisez : </h3>
    <div class="w-full flex flex-col items-start" v-if="delivery.availability === 'partial_time'">
      <label class="text-gray-700 flex items-center py-2 cursor-pointer">
        <input type="radio" v-model="delivery.availability_details" name="availability_details" value="weekend_only" class="form-radio h-4 w-4">
        <span class="pl-2">Weekend uniquement</span>
      </label>
      <label class="text-gray-700 flex items-center py-2 cursor-pointer">
        <input type="radio" v-model="delivery.availability_details" name="availability_details" value="nights_and_weekend" class="form-radio h-4 w-4">
        <span class="pl-2">Soir & weekend</span>
      </label>
      <label class="text-gray-700 flex items-center py-2 cursor-pointer">
        <input type="radio" v-model="delivery.availability_details" name="availability_details" value="somes_days" class="form-radio h-4 w-4">
        <span class="pl-2">Seulement certains jours</span>
      </label>
    </div>

    <h3 class="font-medium mb-2 mt-8">Matériel nécéssaire: </h3>
    <label class="text-gray-700 flex items-center py-2 cursor-pointer">
      <input type="checkbox" v-model="delivery.have_bag" class="form-checkbox h-4 w-4">
      <span class="pl-2">Je certifie que j’exercerai avec un sac isotherme d’une dimension minimale de 35cm (L) x 35cm (l) x 30cm (h). </span>
    </label>
    <span class="text-gray-400 text-sm">
      <i class="material-icons text-base mr-1">info</i>
      Vous pouvez acheter un sac de livraison Delicity sur la boutique en ligne ici <a href="https://pro.delicity.co/boutique/livreurs/sac-de-livraison-delicity/" target="_blank" class="text-blue-400">pro.delicity.co/boutique/livreurs/sac-de-livraison-delicity/</a>
    </span>



    <h3 class="font-medium mb-2 mt-8">Type de commandes que vous êtes capable d’effectuer ? </h3>
    <label class="text-gray-700 flex items-center py-2 cursor-pointer">
      <input type="checkbox" v-model="delivery.small_distance" class="form-checkbox h-4 w-4">
      <span class="pl-2">Courtes distances (&lt;4 km)</span>
    </label>
    <label class="text-gray-700 flex items-center py-2 cursor-pointer">
      <input type="checkbox" v-model="delivery.big_distance" class="form-checkbox h-4 w-4">
      <span class="pl-2">Longues distances (&gt;4km)</span>
    </label>
    <label class="text-gray-700 flex items-center py-2 cursor-pointer">
      <input type="checkbox" v-model="delivery.sensitive_delivery" class="form-checkbox h-4 w-4">
      <span class="pl-2">Livraisons sensibles : pizzas,  boissons, objets fragiles</span>
    </label>
    <label class="text-gray-700 flex items-center py-2 cursor-pointer">
      <input type="checkbox" v-model="delivery.big_capacity" class="form-checkbox h-4 w-4">
      <span class="pl-2">Grande capacité : Capacité de charge supérieure à 1 sac de livraison</span>
    </label>

    <span class="text-gray-400 text-sm">
      <i class="material-icons text-base mr-1">info</i>
      Vous pourrez modifier vos choix à n’importe quel moment dans l’application
    </span>

    <div class="w-full pt-10">
      <span class="text-red-600 pb-4 flex items-center" v-if="errors.length !== 0"><i class="material-icons mr-2">error</i>Corrigez les erreurs du formulaire pour continuer.</span>
      <button v-if="!onboardingFinish" @click="save" class="px-5 py-2 bg-green-400 text-white font-medium rounded-lg flex items-center pr-3">Étape suivante <i class="material-icons pl-2">chevron_right</i></button>
    </div>


  </page>
</template>


<script>
import {mapGetters, mapActions} from 'vuex';
import Page from "../../components/Form/Page";

export default {
  data() {
    return {
      delivery: {
        availability: null,
        availability_details: null,
        have_bag: false,
        small_distance: false,
        big_distance: false,
        sensitive_delivery: false,
        big_capacity: false
      },
      errors: []
    }
  },
  components: {Page},
  methods: {
    ...mapActions(['storeDeliveryInfos']),
    save(){
      this.errors = [];
      this.storeDeliveryInfos({...this.delivery})
          .catch((errors) => {
            this.parseErrors(errors.errors);
          });
    },
    parseErrors(errors){
      this.errors = [];
      for(const e in errors){
        this.errors.push({error:e, message: errors[e][0]});
      }
      console.log(this.errors);
    },
  },
  mounted() {
    if(this.courierData.deliveryInfos){
      this.delivery = this.courierData.deliveryInfos;
    }
  },
  computed: {
    ...mapGetters(['courierData', 'onboardingFinish'])
  }
}
</script>

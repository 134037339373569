<template>
  <div id="app">
    <div v-if="isLoading">
      <svg class="spinner" viewBox="0 0 50 50">
        <circle class="path" cx="25" cy="25" r="20" fill="none" stroke-width="5"></circle>
      </svg>
    </div>
    <div class="fixed bottom-0 w-full bg-yellow-100 px-5 py-4 z-50"  v-if="currentStep !== 6 && onboardingFinish">
      <div class="container flex items-center justify-between text-gray-900">
        <i class="material-icons mr-3 hidden md:block">info</i>
        <span class="font-medium text-sm md:text-base flex-1">Votre inscription est finalisé, vous ne pouvez pas éditer vos informations</span>
        <button @click="changeStep(6)" class="bg-blue-400 text-white px-3 text-sm py-1 ml-5 rounded font-medium">Téléchargement</button>
      </div>
    </div>
    <router-view v-if="!isLoading"/>
  </div>
</template>

<style lang="scss">
.spinner {
  animation: rotate 2s linear infinite;
  z-index: 2;
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -25px 0 0 -25px;
  width: 50px;
  height: 50px;

  & .path {
    stroke: #adadad;
    stroke-linecap: round;
    animation: dash 1.5s ease-in-out infinite;
  }

}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes dash {
  0% {
    stroke-dasharray: 1, 150;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -35;
  }
  100% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -124;
  }
}

</style>

<script>
import {mapGetters, mapActions} from 'vuex';

export default {
  data() {
    return {
      isLoading: true
    }
  },
  components: {},
  methods: {
    ...mapActions(['getOnboardingInfos', 'changeStep'])
  },
  computed: {
    ...mapGetters(['currentStep', 'onboardingFinish'])
  },
  async mounted() {
    this.isLoading = true;
    await this.getOnboardingInfos();
    this.isLoading = false;
  }
}
</script>

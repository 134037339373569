import Vue from 'vue'
import Vuex from 'vuex'
import FormStore from "./FormStore";

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    form: FormStore
  }
})

<template>
  <page title="Contrats et informations légales">

    <div class="text-gray-500">
      <p class="mb-6">
        Vous agissez comme prestataire de service indépendant pour le compte d’autres professionnels ou particuliers. En tant qu’indépendant, vous êtes tenu de connaître les lois et de les respecter. Notamment, le respect du code de la route,  possession d’une pièce d’identité ou titre de séjour valide,  déclaration de revenus à l’état, permis de conduire en cours de validité et capacité de transport si vous exercez avec un véhicule motorisé.
      </p>
      <p class="mb-6">
        Vous êtes aussi responsable des prestations que vous effectuez et en cas de dommages causé par un manquement de votre part ou non execution complète de la prestation, vous pourrez être débités du montant des préjudices dont vous êtes responsables.
      </p>
      <p class="mb-6">
        Une assurance professionnelle est recommandée.
      </p>
      <p class="mb-6">
        Delicity agit comme outil de mise en relation entre professionnels et ne saurait être tenu responsable de vos infractions, agissements ou éventuelles fausses déclarations.
      </p>
    </div>

    <div class="w-full flex flex-col items-start" v-if="!onboardingFinish">
      <label class="text-gray-700 flex items-center py-2 cursor-pointer">
        <input type="checkbox" v-model="law_check" class="form-checkbox h-4 w-4">
        <span class="pl-2" :class="{'text-red-600': errors.find(e => e.error === 'law_check')}">Je certifie être en règle et avoir pris connaissance des lois qui régissent mon activité d’indépendant</span>
      </label>
      <label class="text-gray-700 flex items-center py-2 cursor-pointer">
        <input type="checkbox" v-model="responsibility_check" class="form-checkbox h-4 w-4">
        <span class="pl-2" :class="{'text-red-600': errors.find(e => e.error === 'responsibility_check')}">Je certifie être informé qu’en cas de manquements aux prestations effectuées pour le compte
          des particuliers ou professionnels du réseau Delicity, ma responsabilité personnelle, morale
          et financière pourra être engagée.</span>
      </label>
    </div>


    <h2 class="text-2xl font-bold mt-10">Charte de qualité Delicity</h2>
    <div class="w-full h-64 mt-5 bg-gray-100 rounded-lg overflow-y-auto p-6 terms-box" ref="quality">

    </div>

    <label class="text-gray-900 flex font-semibold items-center py-2 cursor-pointer mt-4" v-if="!onboardingFinish">
      <input type="checkbox" v-model="quality_check" class="form-checkbox h-4 w-4">
      <span class="pl-2" :class="{'text-red-600': errors.find(e => e.error === 'quality_check')}">J’ai pris connaissance et j’accepte la charte Delicity</span>
    </label>



    <h2 class="text-2xl font-bold mt-10">Contract d'inscription livreur</h2>
    <div class="w-full h-64 mt-5 bg-gray-100 rounded-lg overflow-y-auto p-6 terms-box" ref="terms">

    </div>


    <label class="text-gray-900 flex font-semibold items-center py-2 cursor-pointer mt-4" v-if="!onboardingFinish">
      <input type="checkbox" v-model="usage_check" class="form-checkbox h-4 w-4">
      <span class="pl-2" :class="{'text-red-600': errors.find(e => e.error === 'usage_check')}">J’ai pris connaissance et j’accepte les Coonditions d’utilisation pour livreurs</span>
    </label>


    <div class="flex flex-col justify-content items-center text-center" v-if="!onboardingFinish">
      <h2 class="text-2xl font-bold mt-10">Entrez votre signature:</h2>
      <small>Signez dans le cadre gris</small>
      <div class="w-72 rounded-xl h-32 bg-gray-100 mt-2 relative"  :class="{'border-2 border-red-600': errors.find(e => e.error === 'canvas')}">
        <canvas ref="canvas" class="w-full h-full absolute"></canvas>
      </div>
      <small class="text-red-600" v-if="errors.find(e => e.error === 'canvas')">{{errors.find(e => e.error === 'canvas').message}}</small>
      <button  v-if="!onboardingFinish" @click="clearCanvas" class="bg-gray-900 text-white px-4 py-1 mt-2 rounded font-medium text-sm flex items-center"><i class="material-icons text-sm mr-2">sync</i>Effacer la signature</button>

      <span class="text-red-600 pt-8 flex items-center" v-if="errors.length !== 0"><i class="material-icons mr-2">error</i>Corrigez les erreurs du formulaire pour continuer.</span>

      <button @click="save" v-if="!onboardingFinish" class="px-5 py-2 bg-green-400 text-white font-medium rounded-lg flex items-center pr-3 mt-10">Étape suivante <i class="material-icons pl-2">chevron_right</i></button>
    </div>



  </page>
</template>

<style lang="scss">

.terms-box
{
  h1,h2,h3,h4,h5,h6
  {
    font-weight: 600;
    margin-bottom: 10px;
  }
  h1
  {
    font-size: 1.7em;
    font-weight: 700;
  }
  h2
  {
    font-size: 1.5em;
    font-weight: 700;
  }
  h2
  {
    font-size: 1.3em
  }
  p
  {
    margin-bottom: 10px;
    line-height: 1.8;
  }
}

</style>

<script>
import {mapGetters, mapActions} from 'vuex';
import Page from "../../components/Form/Page";
const marked = require("marked");
import SignaturePad from 'signature_pad'

export default {
  data() {
    return {
      law_check: false,
      responsibility_check: false,
      quality_check: false,
      usage_check: false,
      errors: [],
      signCanvas: false
    }
  },
  components: {Page},
  methods: {
    ...mapActions(['storeSign']),
    save(){
      this.errors = [];

      if(this.law_check === false){
        this.errors.push({error:'law_check', message:''})
      }
      if(this.responsibility_check === false){
        this.errors.push({error:'responsibility_check', message:''})
      }
      if(this.quality_check === false){
        this.errors.push({error:'quality_check', message:''})
      }
      if(this.usage_check === false){
        this.errors.push({error:'usage_check', message:''})
      }

      if(this.signCanvas.isEmpty()){
        this.errors.push({error:'canvas', message:'Entrez votre signature'})
      }
      if(this.errors.length > 0){
        return;
      }

      const data = this.signCanvas.toDataURL();
      this.storeSign({sign: data});
    },
    clearCanvas(){
      this.signCanvas.clear();
    }
  },
  computed: {
    ...mapGetters(['terms', 'onboardingFinish', 'courierData'])
  },
  mounted() {
    setTimeout(() => {
      this.signCanvas = new SignaturePad(this.$refs.canvas);

      let address;
      if(this.courierData.companyInfos.personal_address_is_different){
        address = {
          line1: this.courierData.companyInfos.personal_address_line1,
          line2: this.courierData.companyInfos.personal_address_line2 || '',
          zip: this.courierData.companyInfos.personal_postal_code,
          city: this.courierData.companyInfos.personal_city
        }
      }
      else{
        address = {
          line1: this.courierData.companyInfos.company_address_line1,
          line2: this.courierData.companyInfos.company_address_line2 || '',
          zip: this.courierData.companyInfos.company_postal_code,
          city: this.courierData.companyInfos.company_city
        }
      }

      const courierInfos = this.courierData.personalInfos.firstname + ' ' + this.courierData.personalInfos.lastname
          + ' né le ' +  this.courierData.personalInfos.birthdate
          + ' résident au ' + address.line1 + ' ' + address.line2 + ', ' + address.zip + ' ' + address.city

      const usage = this.terms.usage.replace('[courier_infos]', courierInfos)


      this.$refs.quality.innerHTML = marked(this.terms.quality);
      this.$refs.terms.innerHTML = marked(usage);
    }, 200)
  }
}
</script>

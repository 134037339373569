<template>
  <page title="Paiements">

    <div class="flex md:gap-6 flex-col md:flex-row">
      <div class="flex-1 mb-8">
        <label class="font-medium block" :class="{'text-red-600': errors.find(e => e.error === 'iban')}">IBAN*</label>
        <input type="text" v-model="payment.iban">
        <small class="text-red-500" v-if="errors.find(e => e.error === 'iban')">{{errors.find(e => e.error === 'iban').message}}</small>
      </div>
    </div>


    <h3 class="font-medium mb-2">Frequence de paiement: </h3>
    <div class="w-full flex flex-col items-start">
      <label class="text-gray-700 flex items-center py-2 cursor-pointer">
        <input type="radio" v-model="payment.payment_frequency" name="payment_frequency" value="weekly" class="form-radio h-4 w-4">
        <span class="pl-2">Toutes les semaines, le Lundi</span>
      </label>
      <label class="text-gray-700 flex items-center py-2 cursor-pointer">
        <input type="radio" v-model="payment.payment_frequency" name="payment_frequency" value="monthly" class="form-radio h-4 w-4">
        <span class="pl-2">Tous les mois</span>
      </label>
    </div>

    <span class="text-gray-400 text-sm flex mt-4">
      <i class="material-icons mr-3">info</i>
      <div>
        Les versements sont effectués à J+7 minimum après la course. <br>
        Le prestataire de paiement (Stripe) facture systématiquement 0.20% de commission + 0.10€ par virement et 1€ / mois / compte actif.<br>
        Un compte est considéré comme actif s’il effectue au moins 1 commande dans le mois. Les conditions sont imposées
        par stripe sont susceptibles de changer.
      </div>
    </span>



    <div class="w-full pt-6">
      <button @click="save" v-if="!onboardingFinish" class="px-5 py-2 bg-green-400 text-white font-medium rounded-lg flex items-center pr-3">Étape suivante <i class="material-icons pl-2">chevron_right</i></button>
    </div>





  </page>
</template>

<script>
import {mapGetters, mapActions} from 'vuex';
import Page from "../../components/Form/Page";

export default {
  data() {
    return {
      payment: {
        iban: null,
        payment_frequency: 'weekly',
      },
      errors: []
    }
  },
  components: {Page},
  methods: {
    ...mapActions(['storePayments']),
    save(){
      this.storePayments(this.payment)
          .catch((errors) => {
            this.parseErrors(errors.errors);
          });
    },
    parseErrors(errors){
      this.errors = [];
      for(const e in errors){
        this.errors.push({error:e, message: errors[e][0]});
      }
    },
  },
  computed: {
    ...mapGetters(['courierData', 'onboardingFinish'])
  },
  mounted() {
    if(this.courierData.paymentInfos){
      this.payment = this.courierData.paymentInfos;
    }
  }
}
</script>

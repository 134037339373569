<template>
  <page title="Documents">

    <h3 class="font-semibold mb-3">Documents requis: </h3>

    <div class="flex md:gap-6 flex-col md:flex-row">
      <div class="flex-1 mb-8">
        <label class="block" :class="{'text-red-600': errors.find(e => e.error === 'id_front')}">Carte d’identité (Recto)</label>
        <input type="file" class="mt-2" @change="sendDocument($event, 'id_front')" v-if="courierData.documents.id_front === false">


        <div class="w-1/2 flex mt-2 rounded-lg overflow-hidden" v-if="courierData.documents.id_front !== false">
          <div class="flex-1 flex justify-center items-center h-8 leading-none"
               :class="{'bg-gray-200': courierData.documents.id_front === 'loading',
                'bg-blue-400 text-white': courierData.documents.id_front === true,
                'bg-red-400 text-white': courierData.documents.id_front === 'invalid',
          }">
            <span class="font-medium text-sm" v-if="courierData.documents.id_front === 'invalid'">Fichier invalide...</span>
            <span class="font-medium text-sm" v-if="courierData.documents.id_front === 'loading'">Chargement...</span>
            <span class="font-medium text-sm" v-if="courierData.documents.id_front === true">Fichier envoyé</span>
          </div>
          <div class="h-8 w-8 bg-gray-200 flex justify-center cursor-pointer items-center" @click="resetDocument('id_front')">
            <i class="material-icons text-sm">close</i>
          </div>
        </div>
        <small class="text-red-600" v-if="courierData.documents.id_front === 'invalid'">Formats fichier autorisé : PDF, PNG, JPG, BMP</small>

      </div>
      <div class="flex-1 mb-8">
        <label class="block" :class="{'text-red-600': errors.find(e => e.error === 'id_back')}">Carte d’identité (Verso)</label>
        <input type="file" class="mt-2" @change="sendDocument($event, 'id_back')" v-if="courierData.documents.id_back === false">


        <div class="w-1/2 flex mt-2 rounded-lg overflow-hidden" v-if="courierData.documents.id_back !== false">
          <div class="flex-1 flex justify-center items-center h-8 leading-none"
               :class="{'bg-gray-200': courierData.documents.id_back === 'loading',
                'bg-blue-400 text-white': courierData.documents.id_back === true,
                'bg-red-400 text-white': courierData.documents.id_back === 'invalid'
          }">
            <span class="font-medium text-sm" v-if="courierData.documents.id_back === 'invalid'">Fichier invalide...</span>
            <span class="font-medium text-sm" v-if="courierData.documents.id_back === 'loading'">Chargement...</span>
            <span class="font-medium text-sm" v-if="courierData.documents.id_back === true">Fichier envoyé</span>
          </div>
          <div class="h-8 w-8 bg-gray-200 flex justify-center cursor-pointer items-center" @click="resetDocument('id_back')">
            <i class="material-icons text-sm">close</i>
          </div>
        </div>
        <small class="text-red-600" v-if="courierData.documents.id_back === 'invalid'">Formats fichier autorisé : PDF, PNG, JPG, BMP</small>

      </div>
    </div>


    <div class="flex md:gap-6 flex-col md:flex-row">
      <div class="flex-1 mb-8">
        <label class="block" :class="{'text-red-600': errors.find(e => e.error === 'company_document')}">Kbis ou Relevé SIREN</label>
        <input type="file" class="mt-2" @change="sendDocument($event, 'company_document')"  v-if="courierData.documents.company_document === false">

        <div class="w-1/2 flex mt-2 rounded-lg overflow-hidden" v-if="courierData.documents.company_document !== false">
          <div class="flex-1 flex justify-center items-center h-8 leading-none"
               :class="{'bg-gray-200': courierData.documents.company_document === 'loading',
                'bg-blue-400 text-white': courierData.documents.company_document === true,
                'bg-red-400 text-white': courierData.documents.company_document === 'invalid'}">
            <span class="font-medium text-sm" v-if="courierData.documents.company_document === 'invalid'">Fichier invalide...</span>
            <span class="font-medium text-sm" v-if="courierData.documents.company_document === 'loading'">Chargement...</span>
            <span class="font-medium text-sm" v-if="courierData.documents.company_document === true">Fichier envoyé</span>
          </div>
          <div class="h-8 w-8 bg-gray-200 flex justify-center cursor-pointer items-center" @click="resetDocument('company_document')">
            <i class="material-icons text-sm">close</i>
          </div>
        </div>
        <small class="text-red-600" v-if="courierData.documents.company_document === 'invalid'">Formats fichier autorisé : PDF, PNG, JPG, BMP</small>

      </div>
      <div class="flex-1 mb-8">
        <label class="block" :class="{'text-red-600': errors.find(e => e.error === 'proof_of_address')}">Justificatif de domicile</label>
        <input type="file" class="mt-2" @change="sendDocument($event, 'proof_of_address')" v-if="courierData.documents.proof_of_address === false">

        <div class="w-1/2 flex mt-2 rounded-lg overflow-hidden" v-if="courierData.documents.proof_of_address !== false">
          <div class="flex-1 flex justify-center items-center h-8 leading-none"
               :class="{'bg-gray-200': courierData.documents.proof_of_address === 'loading',
                'bg-blue-400 text-white': courierData.documents.proof_of_address === true,
                'bg-red-400 text-white': courierData.documents.proof_of_address === 'invalid'}">
            <span class="font-medium text-sm" v-if="courierData.documents.proof_of_address === 'invalid'">Fichier invalide...</span>
            <span class="font-medium text-sm" v-if="courierData.documents.proof_of_address === 'loading'">Chargement...</span>
            <span class="font-medium text-sm" v-if="courierData.documents.proof_of_address === true">Fichier envoyé</span>
          </div>
          <div class="h-8 w-8 bg-gray-200 flex justify-center cursor-pointer items-center" @click="resetDocument('proof_of_address')">
            <i class="material-icons text-sm">close</i>
          </div>
        </div>
        <small class="text-red-600" v-if="courierData.documents.proof_of_address === 'invalid'">Formats fichier autorisé : PDF, PNG, JPG, BMP</small>

      </div>
    </div>


    <div class="flex md:gap-6 flex-col md:flex-row">
      <div class="flex-1 mb-8">
        <label class=" block" :class="{'text-red-600': errors.find(e => e.error === 'avatar')}">Photo de profil <small>(Image JPG/PNG)</small></label>
        <input type="file" class="mt-2" @change="sendDocument($event, 'avatar')" v-if="courierData.documents.avatar === false">


        <div class="w-1/2 flex mt-2 rounded-lg overflow-hidden" v-if="courierData.documents.avatar !== false">
          <div class="flex-1 flex justify-center items-center h-8 leading-none"
               :class="{'bg-gray-200': courierData.documents.avatar === 'loading',
                'bg-blue-400 text-white': courierData.documents.avatar === true,
                'bg-red-400 text-white': courierData.documents.avatar === 'invalid'}">
            <span class="font-medium text-sm" v-if="courierData.documents.avatar === 'invalid'">Fichier invalide...</span>
            <span class="font-medium text-sm" v-if="courierData.documents.avatar === 'loading'">Chargement...</span>
            <span class="font-medium text-sm" v-if="courierData.documents.avatar === true">Fichier envoyé</span>
          </div>
          <div class="h-8 w-8 bg-gray-200 flex justify-center cursor-pointer items-center" @click="resetDocument('avatar')">
            <i class="material-icons text-sm">close</i>
          </div>
        </div>
        <small class="text-red-600" v-if="courierData.documents.avatar === 'invalid'">Formats fichier autorisé : PNG, JPG, BMP</small>

      </div>
      <div class="flex-1"></div>
    </div>



    <h3 class="font-semibold mt-8 mb-3">Documents complémentaire <small class="font-thin">(optionnel)</small>: </h3>

    <div class="flex md:gap-6 flex-col md:flex-row">
      <div class="flex-1 mb-8">
        <label class="block">Extrait de casier judiciaire</label>
        <input type="file" class="mt-2" @change="sendDocument($event, 'criminal_record')" v-if="courierData.documents.criminal_record === false">

        <div class="w-1/2 flex mt-2 rounded-lg overflow-hidden" v-if="courierData.documents.criminal_record !== false">
          <div class="flex-1 flex justify-center items-center h-8 leading-none"
               :class="{'bg-gray-200': courierData.documents.criminal_record === 'loading',
                'bg-blue-400 text-white': courierData.documents.criminal_record === true,
                'bg-red-400 text-white': courierData.documents.criminal_record === 'invalid'}">
            <span class="font-medium text-sm" v-if="courierData.documents.criminal_record === 'invalid'">Fichier invalide...</span>
            <span class="font-medium text-sm" v-if="courierData.documents.criminal_record === 'loading'">Chargement...</span>
            <span class="font-medium text-sm" v-if="courierData.documents.criminal_record === true">Fichier envoyé</span>
          </div>
          <div class="h-8 w-8 bg-gray-200 flex justify-center cursor-pointer items-center" @click="resetDocument('criminal_record')">
            <i class="material-icons text-sm">close</i>
          </div>
        </div>
        <small class="text-red-600" v-if="courierData.documents.criminal_record === 'invalid'">Formats fichier autorisé : PDF, PNG, JPG, BMP</small>

      </div>
      <div class="flex-1 mb-8">
        <label class="block">Attestation de vigilance</label>
        <input type="file" class="mt-2" @change="sendDocument($event, 'certificate_of_vigilance')" v-if="courierData.documents.certificate_of_vigilance === false">

        <div class="w-1/2 flex mt-2 rounded-lg overflow-hidden" v-if="courierData.documents.certificate_of_vigilance !== false">
          <div class="flex-1 flex justify-center items-center h-8 leading-none"
               :class="{'bg-gray-200': courierData.documents.certificate_of_vigilance === 'loading',
                'bg-blue-400 text-white': courierData.documents.certificate_of_vigilance === true,
                'bg-red-400 text-white': courierData.documents.certificate_of_vigilance === 'invalid'}">

            <span class="font-medium text-sm" v-if="courierData.documents.certificate_of_vigilance === 'invalid'">Fichier invalide...</span>
            <span class="font-medium text-sm" v-if="courierData.documents.certificate_of_vigilance === 'loading'">Chargement...</span>
            <span class="font-medium text-sm" v-if="courierData.documents.certificate_of_vigilance === true">Fichier envoyé</span>
          </div>
          <div class="h-8 w-8 bg-gray-200 flex justify-center cursor-pointer items-center" @click="resetDocument('certificate_of_vigilance')">
            <i class="material-icons text-sm">close</i>
          </div>
        </div>
        <small class="text-red-600" v-if="courierData.documents.certificate_of_vigilance === 'invalid'">Formats fichier autorisé : PDF, PNG, JPG, BMP</small>

      </div>
    </div>
    <div class="flex md:gap-6 flex-col md:flex-row">
      <div class="flex-1 mb-8">
        <label class="block">Capacité de transport</label>
        <input type="file" class="mt-2" @change="sendDocument($event, 'transport_capacity')" v-if="courierData.documents.transport_capacity === false">

        <div class="w-1/2 flex mt-2 rounded-lg overflow-hidden" v-if="courierData.documents.transport_capacity !== false">
          <div class="flex-1 flex justify-center items-center h-8 leading-none"
               :class="{'bg-gray-200': courierData.documents.transport_capacity === 'loading',
                'bg-blue-400 text-white': courierData.documents.transport_capacity === true,
                'bg-red-400 text-white': courierData.documents.transport_capacity === 'invalid'}">
            <span class="font-medium text-sm" v-if="courierData.documents.transport_capacity === 'invalid'">Fichier invalide...</span>
            <span class="font-medium text-sm" v-if="courierData.documents.transport_capacity === 'loading'">Chargement...</span>
            <span class="font-medium text-sm" v-if="courierData.documents.transport_capacity === true">Fichier envoyé</span>
          </div>
          <div class="h-8 w-8 bg-gray-200 flex justify-center cursor-pointer items-center" @click="resetDocument('transport_capacity')">
            <i class="material-icons text-sm">close</i>
          </div>
        </div>
        <small class="text-red-600" v-if="courierData.documents.transport_capacity === 'invalid'">Formats fichier autorisé : PDF, PNG, JPG, BMP</small>

      </div>
      <div class="flex-1"></div>
    </div>


    <div class="w-full pt-10">
      <button @click="save" v-if="!onboardingFinish" class="px-5 py-2 bg-green-400 text-white font-medium rounded-lg flex items-center pr-3">Étape suivante <i class="material-icons pl-2">chevron_right</i></button>
    </div>


  </page>
</template>

<script>
import {mapGetters, mapActions} from 'vuex';
import Page from "../../components/Form/Page";

export default {
  data() {
    return {
      errors: []
    }
  },
  components: {Page},
  methods: {
    ...mapActions(['uploadDocument', 'resetDocument', 'storeDocuments']),
    sendDocument($event, name){
      this.uploadDocument({name:name, file: $event.target.files[0] })
          .catch(() => {

          })
    },
    save(){
      this.storeDocuments()
          .catch((errors) => {
            this.parseErrors(errors.errors);
          });
    },
    parseErrors(errors){
      this.errors = [];
      for(const e in errors){
        this.errors.push({error:e, message: errors[e][0]});
      }
    },
  },
  computed: {
    ...mapGetters(['courierData', 'onboardingFinish'])
  }
}
</script>

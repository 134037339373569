var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('page',{attrs:{"title":"Documents"}},[_c('h3',{staticClass:"font-semibold mb-3"},[_vm._v("Documents requis: ")]),_c('div',{staticClass:"flex md:gap-6 flex-col md:flex-row"},[_c('div',{staticClass:"flex-1 mb-8"},[_c('label',{staticClass:"block",class:{'text-red-600': _vm.errors.find(function (e) { return e.error === 'id_front'; })}},[_vm._v("Carte d’identité (Recto)")]),(_vm.courierData.documents.id_front === false)?_c('input',{staticClass:"mt-2",attrs:{"type":"file"},on:{"change":function($event){return _vm.sendDocument($event, 'id_front')}}}):_vm._e(),(_vm.courierData.documents.id_front !== false)?_c('div',{staticClass:"w-1/2 flex mt-2 rounded-lg overflow-hidden"},[_c('div',{staticClass:"flex-1 flex justify-center items-center h-8 leading-none",class:{'bg-gray-200': _vm.courierData.documents.id_front === 'loading',
              'bg-blue-400 text-white': _vm.courierData.documents.id_front === true,
              'bg-red-400 text-white': _vm.courierData.documents.id_front === 'invalid',
        }},[(_vm.courierData.documents.id_front === 'invalid')?_c('span',{staticClass:"font-medium text-sm"},[_vm._v("Fichier invalide...")]):_vm._e(),(_vm.courierData.documents.id_front === 'loading')?_c('span',{staticClass:"font-medium text-sm"},[_vm._v("Chargement...")]):_vm._e(),(_vm.courierData.documents.id_front === true)?_c('span',{staticClass:"font-medium text-sm"},[_vm._v("Fichier envoyé")]):_vm._e()]),_c('div',{staticClass:"h-8 w-8 bg-gray-200 flex justify-center cursor-pointer items-center",on:{"click":function($event){return _vm.resetDocument('id_front')}}},[_c('i',{staticClass:"material-icons text-sm"},[_vm._v("close")])])]):_vm._e(),(_vm.courierData.documents.id_front === 'invalid')?_c('small',{staticClass:"text-red-600"},[_vm._v("Formats fichier autorisé : PDF, PNG, JPG, BMP")]):_vm._e()]),_c('div',{staticClass:"flex-1 mb-8"},[_c('label',{staticClass:"block",class:{'text-red-600': _vm.errors.find(function (e) { return e.error === 'id_back'; })}},[_vm._v("Carte d’identité (Verso)")]),(_vm.courierData.documents.id_back === false)?_c('input',{staticClass:"mt-2",attrs:{"type":"file"},on:{"change":function($event){return _vm.sendDocument($event, 'id_back')}}}):_vm._e(),(_vm.courierData.documents.id_back !== false)?_c('div',{staticClass:"w-1/2 flex mt-2 rounded-lg overflow-hidden"},[_c('div',{staticClass:"flex-1 flex justify-center items-center h-8 leading-none",class:{'bg-gray-200': _vm.courierData.documents.id_back === 'loading',
              'bg-blue-400 text-white': _vm.courierData.documents.id_back === true,
              'bg-red-400 text-white': _vm.courierData.documents.id_back === 'invalid'
        }},[(_vm.courierData.documents.id_back === 'invalid')?_c('span',{staticClass:"font-medium text-sm"},[_vm._v("Fichier invalide...")]):_vm._e(),(_vm.courierData.documents.id_back === 'loading')?_c('span',{staticClass:"font-medium text-sm"},[_vm._v("Chargement...")]):_vm._e(),(_vm.courierData.documents.id_back === true)?_c('span',{staticClass:"font-medium text-sm"},[_vm._v("Fichier envoyé")]):_vm._e()]),_c('div',{staticClass:"h-8 w-8 bg-gray-200 flex justify-center cursor-pointer items-center",on:{"click":function($event){return _vm.resetDocument('id_back')}}},[_c('i',{staticClass:"material-icons text-sm"},[_vm._v("close")])])]):_vm._e(),(_vm.courierData.documents.id_back === 'invalid')?_c('small',{staticClass:"text-red-600"},[_vm._v("Formats fichier autorisé : PDF, PNG, JPG, BMP")]):_vm._e()])]),_c('div',{staticClass:"flex md:gap-6 flex-col md:flex-row"},[_c('div',{staticClass:"flex-1 mb-8"},[_c('label',{staticClass:"block",class:{'text-red-600': _vm.errors.find(function (e) { return e.error === 'company_document'; })}},[_vm._v("Kbis ou Relevé SIREN")]),(_vm.courierData.documents.company_document === false)?_c('input',{staticClass:"mt-2",attrs:{"type":"file"},on:{"change":function($event){return _vm.sendDocument($event, 'company_document')}}}):_vm._e(),(_vm.courierData.documents.company_document !== false)?_c('div',{staticClass:"w-1/2 flex mt-2 rounded-lg overflow-hidden"},[_c('div',{staticClass:"flex-1 flex justify-center items-center h-8 leading-none",class:{'bg-gray-200': _vm.courierData.documents.company_document === 'loading',
              'bg-blue-400 text-white': _vm.courierData.documents.company_document === true,
              'bg-red-400 text-white': _vm.courierData.documents.company_document === 'invalid'}},[(_vm.courierData.documents.company_document === 'invalid')?_c('span',{staticClass:"font-medium text-sm"},[_vm._v("Fichier invalide...")]):_vm._e(),(_vm.courierData.documents.company_document === 'loading')?_c('span',{staticClass:"font-medium text-sm"},[_vm._v("Chargement...")]):_vm._e(),(_vm.courierData.documents.company_document === true)?_c('span',{staticClass:"font-medium text-sm"},[_vm._v("Fichier envoyé")]):_vm._e()]),_c('div',{staticClass:"h-8 w-8 bg-gray-200 flex justify-center cursor-pointer items-center",on:{"click":function($event){return _vm.resetDocument('company_document')}}},[_c('i',{staticClass:"material-icons text-sm"},[_vm._v("close")])])]):_vm._e(),(_vm.courierData.documents.company_document === 'invalid')?_c('small',{staticClass:"text-red-600"},[_vm._v("Formats fichier autorisé : PDF, PNG, JPG, BMP")]):_vm._e()]),_c('div',{staticClass:"flex-1 mb-8"},[_c('label',{staticClass:"block",class:{'text-red-600': _vm.errors.find(function (e) { return e.error === 'proof_of_address'; })}},[_vm._v("Justificatif de domicile")]),(_vm.courierData.documents.proof_of_address === false)?_c('input',{staticClass:"mt-2",attrs:{"type":"file"},on:{"change":function($event){return _vm.sendDocument($event, 'proof_of_address')}}}):_vm._e(),(_vm.courierData.documents.proof_of_address !== false)?_c('div',{staticClass:"w-1/2 flex mt-2 rounded-lg overflow-hidden"},[_c('div',{staticClass:"flex-1 flex justify-center items-center h-8 leading-none",class:{'bg-gray-200': _vm.courierData.documents.proof_of_address === 'loading',
              'bg-blue-400 text-white': _vm.courierData.documents.proof_of_address === true,
              'bg-red-400 text-white': _vm.courierData.documents.proof_of_address === 'invalid'}},[(_vm.courierData.documents.proof_of_address === 'invalid')?_c('span',{staticClass:"font-medium text-sm"},[_vm._v("Fichier invalide...")]):_vm._e(),(_vm.courierData.documents.proof_of_address === 'loading')?_c('span',{staticClass:"font-medium text-sm"},[_vm._v("Chargement...")]):_vm._e(),(_vm.courierData.documents.proof_of_address === true)?_c('span',{staticClass:"font-medium text-sm"},[_vm._v("Fichier envoyé")]):_vm._e()]),_c('div',{staticClass:"h-8 w-8 bg-gray-200 flex justify-center cursor-pointer items-center",on:{"click":function($event){return _vm.resetDocument('proof_of_address')}}},[_c('i',{staticClass:"material-icons text-sm"},[_vm._v("close")])])]):_vm._e(),(_vm.courierData.documents.proof_of_address === 'invalid')?_c('small',{staticClass:"text-red-600"},[_vm._v("Formats fichier autorisé : PDF, PNG, JPG, BMP")]):_vm._e()])]),_c('div',{staticClass:"flex md:gap-6 flex-col md:flex-row"},[_c('div',{staticClass:"flex-1 mb-8"},[_c('label',{staticClass:" block",class:{'text-red-600': _vm.errors.find(function (e) { return e.error === 'avatar'; })}},[_vm._v("Photo de profil "),_c('small',[_vm._v("(Image JPG/PNG)")])]),(_vm.courierData.documents.avatar === false)?_c('input',{staticClass:"mt-2",attrs:{"type":"file"},on:{"change":function($event){return _vm.sendDocument($event, 'avatar')}}}):_vm._e(),(_vm.courierData.documents.avatar !== false)?_c('div',{staticClass:"w-1/2 flex mt-2 rounded-lg overflow-hidden"},[_c('div',{staticClass:"flex-1 flex justify-center items-center h-8 leading-none",class:{'bg-gray-200': _vm.courierData.documents.avatar === 'loading',
              'bg-blue-400 text-white': _vm.courierData.documents.avatar === true,
              'bg-red-400 text-white': _vm.courierData.documents.avatar === 'invalid'}},[(_vm.courierData.documents.avatar === 'invalid')?_c('span',{staticClass:"font-medium text-sm"},[_vm._v("Fichier invalide...")]):_vm._e(),(_vm.courierData.documents.avatar === 'loading')?_c('span',{staticClass:"font-medium text-sm"},[_vm._v("Chargement...")]):_vm._e(),(_vm.courierData.documents.avatar === true)?_c('span',{staticClass:"font-medium text-sm"},[_vm._v("Fichier envoyé")]):_vm._e()]),_c('div',{staticClass:"h-8 w-8 bg-gray-200 flex justify-center cursor-pointer items-center",on:{"click":function($event){return _vm.resetDocument('avatar')}}},[_c('i',{staticClass:"material-icons text-sm"},[_vm._v("close")])])]):_vm._e(),(_vm.courierData.documents.avatar === 'invalid')?_c('small',{staticClass:"text-red-600"},[_vm._v("Formats fichier autorisé : PNG, JPG, BMP")]):_vm._e()]),_c('div',{staticClass:"flex-1"})]),_c('h3',{staticClass:"font-semibold mt-8 mb-3"},[_vm._v("Documents complémentaire "),_c('small',{staticClass:"font-thin"},[_vm._v("(optionnel)")]),_vm._v(": ")]),_c('div',{staticClass:"flex md:gap-6 flex-col md:flex-row"},[_c('div',{staticClass:"flex-1 mb-8"},[_c('label',{staticClass:"block"},[_vm._v("Extrait de casier judiciaire")]),(_vm.courierData.documents.criminal_record === false)?_c('input',{staticClass:"mt-2",attrs:{"type":"file"},on:{"change":function($event){return _vm.sendDocument($event, 'criminal_record')}}}):_vm._e(),(_vm.courierData.documents.criminal_record !== false)?_c('div',{staticClass:"w-1/2 flex mt-2 rounded-lg overflow-hidden"},[_c('div',{staticClass:"flex-1 flex justify-center items-center h-8 leading-none",class:{'bg-gray-200': _vm.courierData.documents.criminal_record === 'loading',
              'bg-blue-400 text-white': _vm.courierData.documents.criminal_record === true,
              'bg-red-400 text-white': _vm.courierData.documents.criminal_record === 'invalid'}},[(_vm.courierData.documents.criminal_record === 'invalid')?_c('span',{staticClass:"font-medium text-sm"},[_vm._v("Fichier invalide...")]):_vm._e(),(_vm.courierData.documents.criminal_record === 'loading')?_c('span',{staticClass:"font-medium text-sm"},[_vm._v("Chargement...")]):_vm._e(),(_vm.courierData.documents.criminal_record === true)?_c('span',{staticClass:"font-medium text-sm"},[_vm._v("Fichier envoyé")]):_vm._e()]),_c('div',{staticClass:"h-8 w-8 bg-gray-200 flex justify-center cursor-pointer items-center",on:{"click":function($event){return _vm.resetDocument('criminal_record')}}},[_c('i',{staticClass:"material-icons text-sm"},[_vm._v("close")])])]):_vm._e(),(_vm.courierData.documents.criminal_record === 'invalid')?_c('small',{staticClass:"text-red-600"},[_vm._v("Formats fichier autorisé : PDF, PNG, JPG, BMP")]):_vm._e()]),_c('div',{staticClass:"flex-1 mb-8"},[_c('label',{staticClass:"block"},[_vm._v("Attestation de vigilance")]),(_vm.courierData.documents.certificate_of_vigilance === false)?_c('input',{staticClass:"mt-2",attrs:{"type":"file"},on:{"change":function($event){return _vm.sendDocument($event, 'certificate_of_vigilance')}}}):_vm._e(),(_vm.courierData.documents.certificate_of_vigilance !== false)?_c('div',{staticClass:"w-1/2 flex mt-2 rounded-lg overflow-hidden"},[_c('div',{staticClass:"flex-1 flex justify-center items-center h-8 leading-none",class:{'bg-gray-200': _vm.courierData.documents.certificate_of_vigilance === 'loading',
              'bg-blue-400 text-white': _vm.courierData.documents.certificate_of_vigilance === true,
              'bg-red-400 text-white': _vm.courierData.documents.certificate_of_vigilance === 'invalid'}},[(_vm.courierData.documents.certificate_of_vigilance === 'invalid')?_c('span',{staticClass:"font-medium text-sm"},[_vm._v("Fichier invalide...")]):_vm._e(),(_vm.courierData.documents.certificate_of_vigilance === 'loading')?_c('span',{staticClass:"font-medium text-sm"},[_vm._v("Chargement...")]):_vm._e(),(_vm.courierData.documents.certificate_of_vigilance === true)?_c('span',{staticClass:"font-medium text-sm"},[_vm._v("Fichier envoyé")]):_vm._e()]),_c('div',{staticClass:"h-8 w-8 bg-gray-200 flex justify-center cursor-pointer items-center",on:{"click":function($event){return _vm.resetDocument('certificate_of_vigilance')}}},[_c('i',{staticClass:"material-icons text-sm"},[_vm._v("close")])])]):_vm._e(),(_vm.courierData.documents.certificate_of_vigilance === 'invalid')?_c('small',{staticClass:"text-red-600"},[_vm._v("Formats fichier autorisé : PDF, PNG, JPG, BMP")]):_vm._e()])]),_c('div',{staticClass:"flex md:gap-6 flex-col md:flex-row"},[_c('div',{staticClass:"flex-1 mb-8"},[_c('label',{staticClass:"block"},[_vm._v("Capacité de transport")]),(_vm.courierData.documents.transport_capacity === false)?_c('input',{staticClass:"mt-2",attrs:{"type":"file"},on:{"change":function($event){return _vm.sendDocument($event, 'transport_capacity')}}}):_vm._e(),(_vm.courierData.documents.transport_capacity !== false)?_c('div',{staticClass:"w-1/2 flex mt-2 rounded-lg overflow-hidden"},[_c('div',{staticClass:"flex-1 flex justify-center items-center h-8 leading-none",class:{'bg-gray-200': _vm.courierData.documents.transport_capacity === 'loading',
              'bg-blue-400 text-white': _vm.courierData.documents.transport_capacity === true,
              'bg-red-400 text-white': _vm.courierData.documents.transport_capacity === 'invalid'}},[(_vm.courierData.documents.transport_capacity === 'invalid')?_c('span',{staticClass:"font-medium text-sm"},[_vm._v("Fichier invalide...")]):_vm._e(),(_vm.courierData.documents.transport_capacity === 'loading')?_c('span',{staticClass:"font-medium text-sm"},[_vm._v("Chargement...")]):_vm._e(),(_vm.courierData.documents.transport_capacity === true)?_c('span',{staticClass:"font-medium text-sm"},[_vm._v("Fichier envoyé")]):_vm._e()]),_c('div',{staticClass:"h-8 w-8 bg-gray-200 flex justify-center cursor-pointer items-center",on:{"click":function($event){return _vm.resetDocument('transport_capacity')}}},[_c('i',{staticClass:"material-icons text-sm"},[_vm._v("close")])])]):_vm._e(),(_vm.courierData.documents.transport_capacity === 'invalid')?_c('small',{staticClass:"text-red-600"},[_vm._v("Formats fichier autorisé : PDF, PNG, JPG, BMP")]):_vm._e()]),_c('div',{staticClass:"flex-1"})]),_c('div',{staticClass:"w-full pt-10"},[(!_vm.onboardingFinish)?_c('button',{staticClass:"px-5 py-2 bg-green-400 text-white font-medium rounded-lg flex items-center pr-3",on:{"click":_vm.save}},[_vm._v("Étape suivante "),_c('i',{staticClass:"material-icons pl-2"},[_vm._v("chevron_right")])]):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }
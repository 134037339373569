import axios from 'axios'
import config from "../config/config.js";

const instance = axios.create({
  baseURL: config.baseUrl,
  timeout: 10000
});

if (localStorage.getItem('bearer') !== null) {
    instance.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem('bearer')}`;
}
export default instance

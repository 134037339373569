/*eslint no-unused-vars: "error"*/

import axios from "../utils/axios-instance";

export default {
    state: {
        currentStep: 0,
        finish: false,
        zones: false,
        terms: false,
        data: {
            personalInfos: false,
            deliveryInfos: false,
            companyInfos: false,
            documents: {
                id_front: false,
                id_back: false,
                company_document: false,
                proof_of_address: false,
                avatar: false,
                criminal_record: false,
                certificate_of_vigilance: false,
                transport_capacity: false
            },
            paymentInfos: false
        }
    },
    mutations: {
        SET_CURRENT_STEP(state, currentStep){
            state.currentStep = currentStep;
        },
        SET_FINISH(state, finish){
            state.finish = finish;
        },
        SET_ZONES(state, zones){
            state.zones = zones;
        },
        SET_DATA_PERSONAL_INFO(state, data){
            state.data.personalInfos = data;
        },
        SET_DATA_DELIVERY_INFO(state, data){
            state.data.deliveryInfos = data;
        },
        SET_TERMS(state, data){
            state.terms = data;
        },
        SET_DATA_COMPANY_INFO(state, data){
            state.data.companyInfos = data;
        },
        SET_DATA_DOCUMENTS(state, data){
            state.data.documents = data;
        },
        SET_DATA_PAYMENT(state, data){
            state.data.paymentInfos = data;
        },
        ADD_DATA_DOCUMENTS(state, {name, status}){
            state.data.documents[name] = status;
        }
    },
    getters: {
        currentStep: state => state.currentStep,
        zones: state => state.zones,
        terms: state => state.terms,
        courierData: state => state.data,
        onboardingFinish: state => state.finish
    },
    actions: {
        getOnboardingInfos({commit}){
            return new Promise(((resolve, reject) => {
                axios.get('/api/onboarding/courier/infos')
                    .then((response) => {
                        if (response.data.success) {
                            commit('SET_ZONES', response.data.zones);
                            commit('SET_TERMS', response.data.terms);

                            if(response.data.steps){
                                commit('SET_DATA_PERSONAL_INFO', response.data.steps.personalInfos);
                                commit('SET_DATA_DELIVERY_INFO', response.data.steps.delivery);
                                commit('SET_DATA_COMPANY_INFO', response.data.steps.company);
                                commit('SET_DATA_DOCUMENTS', response.data.steps.documents);
                                commit('SET_DATA_PAYMENT', response.data.steps.payment);
                                commit('SET_CURRENT_STEP', response.data.steps.currentStep);
                                commit('SET_FINISH', response.data.steps.onboarded);
                            }
                            else{
                                commit('SET_CURRENT_STEP', 0);
                            }

                            resolve(response.data);
                        } else {
                            reject(response.data.error_code);
                        }
                    })
                    .catch((error) => {
                        reject(error);
                    })
            }));
        },
        storePersonalInfos({commit}, {firstname, lastname, zoneId, phone, email, birthdate, recommendation}){
            return new Promise(((resolve, reject) => {
                axios.post('/api/onboarding/courier/store/personal_info', {firstname:firstname, lastname:lastname, zoneId:zoneId, phone:phone, email:email, birthdate:birthdate, recommendation:recommendation})
                    .then((response) => {
                        if (response.data.success) {
                            commit('SET_CURRENT_STEP', 1);
                            axios.defaults.headers.common.Authorization = `Bearer ${response.data.bearer}`;
                            commit('SET_DATA_PERSONAL_INFO', {firstname:firstname, lastname:lastname, zoneId:zoneId, phone:phone, email:email, birthdate:birthdate, recommendation:recommendation});
                            localStorage.setItem('bearer', response.data.bearer);
                            resolve(response.data);
                        }
                        else {
                            resolve(response.data);
                        }
                    })
                    .catch((error) => {
                        reject(error.response.data);
                    })
            }));
        },
        storeDeliveryInfos({commit}, {availability, availability_details, have_bag, small_distance, big_distance, sensitive_delivery, big_capacity}){
            return new Promise(((resolve, reject) => {
                axios.post('/api/onboarding/courier/store/delivery_infos', {availability:availability, availability_details:availability_details, have_bag:have_bag, small_distance:small_distance, big_distance:big_distance, sensitive_delivery:sensitive_delivery, big_capacity:big_capacity})
                    .then((response) => {
                        if (response.data.success) {
                            commit('SET_CURRENT_STEP', 2);
                            commit('SET_DATA_DELIVERY_INFO', {availability:availability, availability_details:availability_details, have_bag:have_bag, small_distance:small_distance, big_distance:big_distance, sensitive_delivery:sensitive_delivery, big_capacity:big_capacity});
                            resolve(response.data);
                        } else {
                            reject(response.data.error_code);
                        }
                    })
                    .catch((error) => {
                        reject(error.response.data);
                    })
            }));
        },
        storeCompanyInfos({commit}, data){
            return new Promise(((resolve, reject) => {
                axios.post('/api/onboarding/courier/store/company_infos', data)
                    .then((response) => {
                        if (response.data.success) {
                            commit('SET_CURRENT_STEP', 3);
                            commit('SET_DATA_COMPANY_INFO', data);
                            resolve(response.data);
                        } else {
                            reject(response.data.error_code);
                        }
                    })
                    .catch((error) => {
                        reject(error.response.data);
                    })
            }));
        },

        // eslint-disable-next-line
        uploadDocument({commit}, {name, file}) { // eslint-disable-line no-use-before-define
            return new Promise(((resolve, reject) => {
                let formData = new FormData();
                formData.append('file', file);
                formData.append('name', name);
                commit('ADD_DATA_DOCUMENTS', {name: name, status: 'loading'})

                axios.post('/api/onboarding/courier/store/upload_document', formData)
                    .then((response) => {
                        if (response.data.success) {
                            commit('ADD_DATA_DOCUMENTS', {name: name, status: true})
                            resolve(response.data);
                        } else {
                            reject(response.data.error_code);
                        }
                    })
                    .catch((error) => {
                         commit('ADD_DATA_DOCUMENTS', {name: name, status: 'invalid'})
                        reject(error);
                    })
            }));
        },

        resetDocument({commit}, name){
            commit('ADD_DATA_DOCUMENTS', {name: name, status: false});
            return new Promise(((resolve, reject) => {
                axios.post('/api/onboarding/courier/store/delete_document', {name: name})
                    .then((response) => {
                        if (response.data.success) {

                            resolve(response.data);
                        } else {
                            reject(response.data.error_code);
                        }
                    })
                    .catch((error) => {
                        reject(error);
                    })
            }));

        },

        storeDocuments({commit}){
            return new Promise(((resolve, reject) => {
                axios.post('/api/onboarding/courier/store/documents', {})
                    .then((response) => {
                        if (response.data.success) {
                            commit('SET_CURRENT_STEP', 4);
                            resolve(response.data);
                        } else {
                            reject(response.data.error_code);
                        }
                    })
                    .catch((error) => {
                        reject(error.response.data);
                    })
            }));
        },

        storePayments({commit}, {iban, payment_frequency}){
            return new Promise(((resolve, reject) => {
                axios.post('/api/onboarding/courier/store/payments', {iban: iban, payment_frequency:payment_frequency})
                    .then((response) => {
                        if (response.data.success) {
                            commit('SET_CURRENT_STEP', 5);

                            commit('SET_DATA_PAYMENT', {
                                iban: iban,
                                payment_frequency: payment_frequency
                            });

                            resolve(response.data);
                        } else {
                            reject(response.data.error_code);
                        }
                    })
                    .catch((error) => {
                        reject(error.response.data);
                    })
            }));
        },

        storeSign({commit}, {sign}){
            return new Promise(((resolve, reject) => {
                axios.post('/api/onboarding/courier/store/sign', {sign: sign})
                    .then((response) => {
                        if (response.data.success) {
                            commit('SET_CURRENT_STEP', 6);
                            commit('SET_FINISH', true);

                            resolve(response.data);
                        } else {
                            reject(response.data.error_code);
                        }
                    })
                    .catch((error) => {
                        reject(error.response.data);
                    })
            }));
        },

        // eslint-disable-next-line no-unused-vars
        checkContinuePin({commit}, {pin, token}){
            return new Promise(((resolve, reject) => {
                axios.post('/api/onboarding/courier/check_continue_pin', {pin:pin, token:token})
                    .then((response) => {
                        if (response.data.success) {
                            axios.defaults.headers.common.Authorization = `Bearer ${response.data.bearer}`;
                            localStorage.setItem('bearer', response.data.bearer);
                            resolve(response.data);
                        } else {
                            reject(response.data.error_code);
                        }
                    })
                    .catch((error) => {
                        reject(error.response.data);
                    })
            }));
        },

        // eslint-disable-next-line no-unused-vars
        resendContinuePin({commit}, {email:email}){
            return new Promise(((resolve, reject) => {
                axios.post('/api/onboarding/courier/resend_continue_pin', {email: email})
                    .then((response) => {
                        if (response.data.success) {
                            resolve(response.data);
                        } else {
                            reject(response.data.error_code);
                        }
                    })
                    .catch((error) => {
                        reject(error);
                    })
            }));
        },

        changeStep({commit}, step){
            commit('SET_CURRENT_STEP', parseInt(step))
        }
    }
}

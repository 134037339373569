<template>
  <page title="Informations professionnelles">

    <label class="text-gray-700 flex items-center py-2 cursor-pointer" :class="{'text-red-600': errors.find(e => e.error === 'can_work_in_france')}">
      <input type="checkbox"  v-model="company.can_work_in_france" class="form-checkbox h-4 w-4">
      <span class="pl-2 font-medium">Je confirme que j’ai le droit de créer ou d'exercer une entreprise en France</span>
    </label>

    <h3 class="font-medium mb-2 mt-8" :class="{'text-red-600': errors.find(e => e.error === 'entity_type')}">Type d’entité: </h3>
    <div class="w-full flex flex-col items-start" >
      <label class="text-gray-700 flex items-center py-2 cursor-pointer" :class="{'text-red-600': errors.find(e => e.error === 'entity_type')}">
        <input type="radio"  v-model="company.entity_type" name="entity_type" value="personal" class="form-radio h-4 w-4">
        <span class="pl-2">Particulier ou entreprise individuelle</span>
      </label>
      <label class="text-gray-300 flex items-center py-2 cursor-pointer" :class="{'text-red-600': errors.find(e => e.error === 'entity_type')}">
        <input type="radio" disabled  v-model="company.entity_type" name="entity_type" value="company" class="form-radio h-4 w-4">
        <span class="pl-2">Société</span>
      </label>
    </div>


    <div class="flex md:gap-6 flex-col md:flex-row">
      <div class="flex-1 mt-8">
        <label class="font-medium block" :class="{'text-red-600': errors.find(e => e.error === 'company_name')}">Dénomination sociale de l'entreprise</label>
        <input type="text" v-model="company.company_name">
        <span class="text-gray-400 text-sm">
          <i class="material-icons text-base mr-1">info</i>
          Votre nom s’il s’agit d’une entreprise individuelle
        </span>
      </div>
      <div class="flex-1 mt-8">
        <label class="font-medium block" :class="{'text-red-600': errors.find(e => e.error === 'company_number')}">SIRET</label>
        <input type="text" v-model="company.company_number">
      </div>
    </div>

    <div class="flex md:gap-6 flex-col md:flex-row">
      <div class="flex-1 mt-8">
        <label class="font-medium" :class="{'text-red-600': errors.find(e => e.error === 'company_address_line1')}">Adresse de l’entreprise</label>
        <input type="text" v-model="company.company_address_line1">
      </div>
    </div>

    <div class="flex md:gap-6 flex-col md:flex-row">
      <div class="flex-1 mt-8">
        <label class="font-medium block" :class="{'text-red-600': errors.find(e => e.error === 'company_address_line2')}">Complément d’adresse</label>
        <input type="text" v-model="company.company_address_line2">
      </div>
      <div class="flex-1 mt-8">
        <label class="font-medium block" :class="{'text-red-600': errors.find(e => e.error === 'company_postal_code')}">Code postal</label>
        <input type="number" v-model="company.company_postal_code">
      </div>
      <div class="flex-1 mt-8">
        <label class="font-medium block" :class="{'text-red-600': errors.find(e => e.error === 'company_city')}">Ville</label>
        <input type="text" v-model="company.company_city">
      </div>
    </div>


    <label class="text-gray-700 flex items-center py-2 cursor-pointer mt-8">
      <input type="checkbox" v-model="company.personal_address_is_different" class="form-checkbox h-4 w-4">
      <span class="pl-2">Mon adresse personnelle est différente</span>
    </label>

    <div class="flex md:gap-6 flex-col md:flex-row mt-8" v-if="company.personal_address_is_different">
      <div class="flex-1 mt-8">
        <label class="font-medium block" :class="{'text-red-600': errors.find(e => e.error === 'personal_address_line1')}">Adresse personnelle</label>
        <input type="text" v-model="company.personal_address_line1">
      </div>
    </div>

    <div class="flex md:gap-6 flex-col md:flex-row" v-if="company.personal_address_is_different">
      <div class="flex-1 mt-8">
        <label class="font-medium block" :class="{'text-red-600': errors.find(e => e.error === 'personal_address_line2')}">Complément d’adresse</label>
        <input type="text" v-model="company.personal_address_line2">
      </div>
      <div class="flex-1 mt-8">
        <label class="font-medium block" :class="{'text-red-600': errors.find(e => e.error === 'personal_postal_code')}">Code postal</label>
        <input type="number" v-model="company.personal_postal_code">
      </div>
      <div class="flex-1 mt-8">
        <label class="font-medium block" :class="{'text-red-600': errors.find(e => e.error === 'personal_city')}">Ville</label>
        <input type="text" v-model="company.personal_city">
      </div>
    </div>


    <div class="w-full pt-10">
      <span class="text-red-600 pb-4 flex items-center" v-if="errors.length !== 0"><i class="material-icons mr-2">error</i>Corrigez les erreurs du formulaire pour continuer.</span>
      <button v-if="!onboardingFinish" @click="save" class="px-5 py-2 bg-green-400 text-white font-medium rounded-lg flex items-center pr-3">Étape suivante <i class="material-icons pl-2">chevron_right</i></button>
    </div>

  </page>
</template>

<script>
import {mapGetters, mapActions} from 'vuex';
import Page from "../../components/Form/Page";

export default {
  data() {
    return {
      company: {
        can_work_in_france: false,
        entity_type: null,
        company_name: null,
        company_number: null,
        company_address_line1: null,
        company_address_line2: null,
        company_postal_code: null,
        company_city: null,
        personal_address_is_different: false,
        personal_address_line1: null,
        personal_address_line2: null,
        personal_postal_code: null,
        personal_city: null
      },
      errors: []
    }
  },
  components: {Page},
  methods: {
    ...mapActions(['storeCompanyInfos']),
    save(){
      this.errors = [];
      this.storeCompanyInfos(this.company)
          .catch((errors) => {
            this.parseErrors(errors.errors);
          });
    },
    parseErrors(errors){
      this.errors = [];
      for(const e in errors){
        this.errors.push({error:e, message: errors[e][0]});
      }
      console.log(this.errors);
    },
  },
  mounted() {
    if(this.courierData.companyInfos){
      this.company = this.courierData.companyInfos;
    }
  },
  computed: {
    ...mapGetters(['courierData', 'onboardingFinish'])
  }
}
</script>

<template>
  <page title="Demande de création de compte livreur Delicity">
    <div class="w-full">
      <label class="font-medium" :class="{'text-red-600': errors.find(e => e.error === 'zoneId')}">Dans quelle ville souhaitez-vous exercer ?*</label>
      <select class="form-select" v-model="personal.zoneId" :class="{'text-red-400': errors.find(e => e.error === 'zoneId')}">
        <option :value="null">Indiquez votre ville...</option>
        <option :value="z.id" v-for="z in zones" :key="z.id">{{ z.name }}</option>
      </select>
    </div>

    <div class="flex md:gap-6 flex-col md:flex-row">
      <div class="flex-1 mt-8">
        <label class="font-medium block" :class="{'text-red-600': errors.find(e => e.error === 'firstname')}">Votre prénom*</label>
        <input type="text" v-model="personal.firstname" :class="{'text-red-400': errors.find(e => e.error === 'firstname')}" placeholder="Ex: Pierre">
        <small class="text-red-500" v-if="errors.find(e => e.error === 'firstname')">{{errors.find(e => e.error === 'firstname').message}}</small>
      </div>
      <div class="flex-1 mt-8">
        <label class="font-medium block" :class="{'text-red-600': errors.find(e => e.error === 'lastname')}">Votre nom*</label>
        <input type="text" v-model="personal.lastname" :class="{'text-red-400': errors.find(e => e.error === 'lastname')}" placeholder="Ex: Dupont">
        <small class="text-red-500" v-if="errors.find(e => e.error === 'lastname')">{{errors.find(e => e.error === 'lastname').message}}</small>
      </div>
    </div>

    <div class="flex md:gap-6 flex-col md:flex-row">
      <div class="flex-1 mt-8">
        <label class="font-medium block" :class="{'text-red-600': errors.find(e => e.error === 'phone')}">Portable*</label>

        <vue-tel-input v-model="personal.phone" @validate="validatePhone" :input-options="{placeholder: 'Entrez votre numéro de téléphone'}" class="mt-2" mode="international"></vue-tel-input>

        <small class="text-red-500" v-if="errors.find(e => e.error === 'phone')">{{errors.find(e => e.error === 'phone').message}}</small>
      </div>
      <div class="flex-1 mt-8">
        <label class="font-medium block" :class="{'text-red-600': errors.find(e => e.error === 'email')}">Email*</label>
        <input type="email" v-model="personal.email" :class="{'text-red-400': errors.find(e => e.error === 'email')}" placeholder="Ex: email@domaine.com">
        <small class="text-red-500" v-if="errors.find(e => e.error === 'email')">{{errors.find(e => e.error === 'email').message}}</small>
      </div>
    </div>

    <div class="flex md:gap-6 flex-col md:flex-row">
      <div class="flex-1 mt-8">
        <label class="font-medium block" :class="{'text-red-600': errors.find(e => e.error === 'birthdate')}">Date de naissance*</label>
        <div class="bg-gray-100 mt-2 rounded-lg w-full" style="height: 50px">
          <input type="date" style="margin-top: 0 !important;" placeholder="Date de naissance" v-model="personal.birthdate" :class="{'text-red-400': errors.find(e => e.error === 'birthdate')}">
        </div>
        <small class="text-red-500" v-if="errors.find(e => e.error === 'birthdate')">{{errors.find(e => e.error === 'birthdate').message}}</small>
      </div>
      <div class="flex-1 mt-8">
        <label class="font-medium block" :class="{'text-red-600': errors.find(e => e.error === 'recommendation')}">Quel restaurateur vous recommande ?</label>
        <input type="text" v-model="personal.recommendation" :class="{'text-red-400': errors.find(e => e.error === 'recommendation')}">
        <small class="text-red-500" v-if="errors.find(e => e.error === 'recommendation')">{{errors.find(e => e.error === 'recommendation').message}}</small>
      </div>
    </div>


    <div class="w-full pt-10">
      <span class="text-red-600 pb-4 flex items-center" v-if="errors.length !== 0"><i class="material-icons mr-2">error</i>Corrigez les erreurs du formulaire pour continuer.</span>
      <button @click="save" v-if="!onboardingFinish" class="px-5 py-2 bg-green-400 text-white font-medium rounded-lg flex items-center pr-3">Étape suivante <i class="material-icons pl-2">chevron_right</i></button>
    </div>
  </page>
</template>

<script>
import {mapGetters, mapActions} from 'vuex';
import Page from "../../components/Form/Page";

export default {
  data() {
    return {
      personal: {
        firstname: null,
        lastname: null,
        zoneId: null,
        phone: null,
        email: null,
        birthdate: null,
        recommendation: null
      },
      isPhoneValid: false,
      errors: []
    }
  },
  components: {Page},
  methods: {
    ...mapActions(['storePersonalInfos']),
    save(){
      this.errors = [];

      if(!this.isPhoneValid){
        this.errors.push({error: 'phone', message: 'Numéro de téléphone non-valide'});
        return;
      }

      this.storePersonalInfos({...this.personal})
          .then((response) => {
            if(response.success === false){
              if(response.next_action === 'confirmation_pin'){
                this.$router.push('/pin/'+response.token+'?email='+this.personal.email);
              }
            }
          })
          .catch((errors) => {
            this.parseErrors(errors.errors);
          });
    },
    parseErrors(errors){
      this.errors = [];
      for(const e in errors){
        this.errors.push({error:e, message: errors[e][0]});
      }
    },
    validatePhone(phone){
      this.isPhoneValid = phone.valid;
    }
  },
  mounted() {
    if(this.courierData.personalInfos){
      this.personal = this.courierData.personalInfos;
    }
  },
  computed: {
    ...mapGetters(['zones', 'courierData', 'onboardingFinish'])
  }
}
</script>

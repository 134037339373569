<template>
  <div>
    <div class="w-full p-5 flex justify-center items-center flex-col">
      <img src="/assets/images/delicity.svg" class="h-16">
    </div>

    <div class="md:mt-5 mb-20 w-full md:w-120 mx-auto">
      <div class="md:border border-gray-100 w-full px-10 md:px-5 py-5 md:px-10 md:py-8 pb-12 rounded-xl md:shadow-sm">

        <h1 class="text-3xl font-extrabold text-gray-800">Continuer votre inscription</h1>

        <div class="mb-4 mt-6">
          <span class="block mb-2">Nous vous avons envoyé un code par email pour continuer votre inscription</span>
          <input type="number" v-model="code" placeholder="Code" class="text-xl font-medium" :class="{'text-red-400': errors.find(e => e.error === 'code')}">
          <small class="text-red-500" v-if="errors.find(e => e.error === 'code')">{{errors.find(e => e.error === 'code').message}}</small>
        </div>

        <div class="flex gap-2 flex-wrap mt-10">
          <button @click="resend" class="px-5 py-2 bg-gray-100 text-black font-medium rounded-lg flex items-center" v-if="errors.length > 0 || showResendButton">Réenvoyer un mail</button>
          <button @click="save" class="px-5 py-2 bg-green-400 text-white font-medium rounded-lg flex items-center">Continuer mon inscription</button>
        </div>

      </div>
    </div>

  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {
  name: "Pin",
  components: {},
  data() {
    return {
      code: '',
      errors: [],
      showResendButton: false
    }
  },
  methods: {
    ...mapActions(['checkContinuePin', 'resendContinuePin', 'getOnboardingInfos']),
    validate(){
      this.errors = [];
      if(this.code === ''){
        this.errors.push({error: 'code', message: 'Merci de rentrer le code que vous avez reçus par email.'})
      }
      if(this.errors.length > 0){
        return false;
      }
      return true;
    },
    parseErrors(errors){
      this.errors = [];
      for(const e in errors){
        this.errors.push({error:e, message: errors[e][0]});
      }
    },
    save(){
      if(!this.validate()){
        return;
      }
      this.checkContinuePin({pin: this.code, token: this.$route.params.token})
          .then(() => {
            this.getOnboardingInfos()
                .then(() => {
                  this.$router.push('/?step='+this.currentStep);
                })
                .catch(() => {
                  alert('Impossible de retrouver votre inscription, merci de réessayer avec un autre email.');
                  localStorage.clear();
                  location.href = '/';
                })
          })
          .catch((errors) => {
            this.parseErrors(errors.errors);
          });
    },
    resend(){
      this.code = '';
      this.resendContinuePin({email: this.$route.query.email})
          .then((response) => {
            this.$router.push('/pin/'+response.token+'?email='+this.$route.query.email);
          })
    }
  },
  computed: {
    ...mapGetters(['currentStep'])
  },
  mounted() {
    if(localStorage.getItem('bearer')){
      this.getOnboardingInfos()
          .then(() => {
            this.$router.push('/?step='+this.currentStep);
          })
          .catch(() => {
            alert('Impossible de retrouver votre inscription, merci de réessayer avec un autre email.');
            localStorage.clear();
            location.href = '/';
          })
    }

    // Show resend button in 60 seconds ...
    setTimeout(() => {
      this.showResendButton = true;
    }, 60000)

  }
}
</script>

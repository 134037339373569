<template>
  <div class="w-full p-5 flex justify-center items-center flex-col">
    <img src="/assets/images/delicity.svg" class="h-10 mb-8 mt-4">

    <div class="p-4 w-full justify-center items-center hidden md:flex">
      <div class="h-2 w-28 bg-gray-100 mx-2 flex items-start justify-center" :class="{'bg-green-400': currentStep >= 0}">
        <span class="text-sm font-medium mt-4 text-gray-400" :class="{'text-green-500': currentStep > 0, 'text-gray-700': currentStep === 0}">Coordonnées</span>
      </div>
      <div class="h-2 w-28 bg-gray-100 mx-2 flex items-start justify-center" :class="{'bg-green-400': currentStep >= 1}">
        <span class="text-sm font-medium mt-4 text-gray-400" :class="{'text-green-500': currentStep > 1, 'text-gray-700': currentStep === 1}">Livraisons</span>
      </div>
      <div class="h-2 w-28 bg-gray-100 mx-2 flex items-start justify-center" :class="{'bg-green-400': currentStep >= 2}">
        <span class="text-sm font-medium mt-4 text-gray-400" :class="{'text-green-500': currentStep > 2, 'text-gray-700': currentStep === 2}">Informations</span>
      </div>
      <div class="h-2 w-28 bg-gray-100 mx-2 flex items-start justify-center" :class="{'bg-green-400': currentStep >= 3}">
        <span class="text-sm font-medium mt-4 text-gray-400" :class="{'text-green-500': currentStep > 3, 'text-gray-700': currentStep === 3}">Documents</span>
      </div>
      <div class="h-2 w-28 bg-gray-100 mx-2 flex items-start justify-center" :class="{'bg-green-400': currentStep >= 4}">
        <span class="text-sm font-medium mt-4 text-gray-400" :class="{'text-green-500': currentStep > 4, 'text-gray-700': currentStep === 4}">Banque</span>
      </div>
      <div class="h-2 w-28 bg-gray-100 mx-2 flex items-start justify-center" :class="{'bg-green-400': currentStep >= 5}">
        <span class="text-sm font-medium mt-4 text-gray-400" :class="{'text-green-500': currentStep > 5, 'text-gray-700': currentStep === 5}">Signature</span>
      </div>
      <div class="h-2 w-28 bg-gray-100 mx-2 flex items-start justify-center" :class="{'bg-green-400': currentStep >= 6}">
        <span class="text-sm font-medium mt-4 text-gray-400" :class="{'text-green-500': currentStep > 6, 'text-gray-700': currentStep === 6}">C’est Parti !</span>
      </div>
    </div>

    <div class="flex md:hidden">
      <span class="font-medium text-xl px-4 py-1 bg-green-400 text-white rounded-2xl">Étape {{currentStep+1}}/7</span>
    </div>


  </div>
</template>

<script>
import {mapGetters, mapActions} from 'vuex';

export default {
  data() {
    return {}
  },
  methods: {
    ...mapActions([])
  },
  computed: {
    ...mapGetters(['currentStep'])
  }
}
</script>

<template>
  <div>
    <nav-form></nav-form>

    <personal-infos v-if="currentStep === 0"></personal-infos>
    <delivery-infos v-if="currentStep === 1"></delivery-infos>
    <company-infos v-if="currentStep === 2"></company-infos>
    <documents v-if="currentStep === 3"></documents>
    <payment-infos v-if="currentStep === 4"></payment-infos>
    <contracts v-if="currentStep === 5"></contracts>
    <end-page v-if="currentStep === 6"></end-page>

  </div>
</template>

<script>
import {mapGetters, mapActions} from 'vuex';
import Nav from "../components/Form/Nav";
import PersonalInfos from "./Form/PersonalInfos";
import Delivery from "./Form/Delivery";
import CompanyInfos from "./Form/CompanyInfos";
import Documents from "./Form/Documents";
import Payments from "./Form/Payments";
import Contracts from "./Form/Contracts";
import End from "./Form/End";

export default {
  data() {
    return {}
  },
  components: {
    'nav-form': Nav,
    'personal-infos':PersonalInfos,
    'delivery-infos': Delivery,
    'company-infos': CompanyInfos,
    'documents': Documents,
    'payment-infos': Payments,
    'contracts': Contracts,
    'end-page': End
  },
  methods: {
    ...mapActions(['changeStep'])
  },
  computed: {
    ...mapGetters(['currentStep'])
  },
  mounted() {
    if(this.$route.query.step){
      if(this.currentStep >= this.$route.query.step){
        this.changeStep(this.$route.query.step);
      }
    }
    else{
      this.$router.push('/?step='+this.currentStep)
    }
  },
  watch: {
    currentStep(step, old){
      if(old < step){
        this.$router.push('/?step='+step)
      }
    },
    $route(step, old){
      if(old.query.step > step.query.step){
        if(this.currentStep >= step.query.step){
          this.changeStep(step.query.step);
        }
      }
    }
  }
}
</script>

<template>
  <page title="Merci !">


      <span class="font-semibold text-lg">Demande d’inscription bien reçue :  🚦 Vous êtes en file d’attente 🚦 </span>

      <div class="mt-5 mb-3">
        Merci pour l’inscription {{courierData.personalInfos.firstname}},
      </div>
      <div>
        Nous avons bien reçu vos informations, vous êtes désormais inscrit en file d’attente.
      </div>
      <div>
        Nous allons vérifier vos documents et revenir vers vous lorsque ce sera votre tour.
      </div>
      <div class="mt-5 font-semibold">
        Vous recevrez vos identifiants par SMS et par Email une fois votre compte validé !
      </div>
      <div class="mt-5">
        - A très bientôt sur Delicity ! 👋🚴‍♂️
      </div>

      <div class="flex md:gap-6 flex-col md:flex-row my-14">
        <div class="flex-1 px-10">
          <label class="font-medium block">Application IOS</label>
          <a target="_blank" href="http://api.delicity.co/appstore" class="block h-14 rounded w-full bg-gray-100 flex items-center justify-center mt-2 font-medium text-lg">Télécharger sur Iphone</a>
        </div>
        <div class="flex-1 px-10">
          <label class="font-medium block">Application Android</label>
          <a target="_blank" href="http://api.delicity.co/playstore" class="block h-14 rounded w-full bg-gray-100 flex items-center justify-center mt-2 font-medium text-lg">Télécharger sur Android</a>
        </div>
      </div>


      <div class="font-semibold underline">
        Pourquoi suis-je en file d’attente ?
      </div>
      <div class="mt-3">
        Dans chaque zone, nous essayons de maintenir un nombre de livreurs cohérent
        par rapport à la demande des clients.
      </div>


      <div class="mt-5">
        Ainsi nous essayons de ne pas faire chuter le chiffre d’affaire des livreurs actifs
        sur Delicity.
      </div>


      <div class="mt-5">
        Dès que l’activité augmente, nous faisons rentrer de nouveaux livreurs, <br>donc en attendant, commandez sur Delicity 😉
      </div>


      <div class="font-semibold mt-5 mb-3">
        Quelques astuces pour bien démarrer avec Delicity et obtenir plus de courses :
      </div>

      <ul class="list-disc ml-5 leading-8">
        <li>Assurez une qualité de service irréprochable autant pour les restaurants que les clients.</li>
        <li>Assurez-vous d’avoir l’équipement et une tenue adéquate pour effectuer des livraisons</li>
        <li>Pensez à vous connecter aussi souvent que possible et vous recevrez de plus en plus de courses.</li>
        <li>Respectez des délais d’approche ou de livraison raisonnables.</li>
        <li>Ne ratez pas les heures de fortes demande (Fin de semaine, soirées ou évenements)</li>
      </ul>




  </page>
</template>

<script>
import {mapGetters, mapActions} from 'vuex';
import Page from "../../components/Form/Page";

export default {
  data() {
    return {}
  },
  components: {Page},
  methods: {
    ...mapActions([])
  },
  computed: {
    ...mapGetters(['courierData'])
  }
}
</script>

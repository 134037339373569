<template>
  <div class="container md:mt-10 mb-20">
    <div class="md:border border-gray-100 w-full px-5 py-5 md:px-10 md:py-8 pb-12 rounded-xl md:shadow-sm">
      <div slot="title" class="mb-10">
        <h1 class="text-3xl font-extrabold text-gray-800">{{title}}</h1>
      </div>
      <slot></slot>
    </div>

  </div>
</template>

<script>
import {mapGetters, mapActions} from 'vuex';

export default {
  props: {
    title: {
      default: '',
      type: String
    }
  },
  data() {
    return {}
  },
  methods: {
    ...mapActions([])
  },
  computed: {
    ...mapGetters(['currentStep'])
  }
}
</script>
